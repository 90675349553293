import { useEffect, useState } from 'react'

import { AADServerParamKeys } from '@azure/msal-common/browser'
import { captureEvent } from '@sentry/browser'
import {
  ReceivedAzureTokenMutation,
  ReceivedAzureTokenMutationVariables,
} from 'types/graphql'

import { Metadata, useMutation } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

const RECEIVED_AZURE = gql`
  mutation ReceivedAzureTokenMutation($input: AzureReceivedAppCodeInput!) {
    azureReceivedAppCode(input: $input)
  }
`

const bc = new BroadcastChannel('sentiment_analysis_azure_authorisation')

const sendBroadcast = (status: string) => {
  bc.postMessage(status)
}

/**
 * Ensure the URI for the domain is registered in the Azure App Registration
 * https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/Authentication/appId/6df454c8-aede-4ca5-bc99-bc72799336d6/isMSAApp~/false
 */
const AzureAppAuthPage = () => {
  const [showError, setShowError] = useState(false)

  const urlParams = new URLSearchParams(window.location.search)
  const code = urlParams.get(AADServerParamKeys.CODE)
  const clientInfo = urlParams.get(AADServerParamKeys.CLIENT_INFO)
  const state = urlParams.get(AADServerParamKeys.STATE)
  const error = urlParams.get(AADServerParamKeys.ERROR)

  const [receivedAzureToken] = useMutation<
    ReceivedAzureTokenMutation,
    ReceivedAzureTokenMutationVariables
  >(RECEIVED_AZURE, {
    onCompleted: () => {
      sendBroadcast('Success')
      window.close()
    },
    onError: () => {
      setShowError(true)
      sendBroadcast('Failed')
      window.close()
    },
  })

  useEffect(() => {
    if (error) {
      captureEvent({
        message: 'Failed to save Azure token',
        level: 'error',
        extra: {
          error,
          clientInfo,
          state,
        },
      })
    }

    const saveToken = async () => {
      if (!code || !state || !clientInfo || error) {
        setShowError(true)
        sendBroadcast('Failed')
        window.close()
        return
      }
      await receivedAzureToken({
        variables: {
          input: {
            code,
            clientInfo: clientInfo,
            state,
          },
        },
      })
    }

    saveToken()
  }, [])

  return (
    <>
      <Metadata title="AzureResponse" description="AzureResponse page" />

      <div className="grid min-h-screen place-items-center">
        {showError && (
          <p className="text-center">
            There was an error processing your authentication request.
            <br />
            Please close this tab and try again.
          </p>
        )}
        {!showError && <LoadingSpinner />}
      </div>
    </>
  )
}

export default AzureAppAuthPage
